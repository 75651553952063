@import "./variables.scss";

// ******************************************************************
// STYLES
.terminal__drawer__container__dark {
	background-color: black !important;
	height: 100%;
	padding: 5px;
}
.terminal__drawer__container__blue {
	height: 100%;
	padding: 5px;
}
.terminal__drawer__container__light {
	height: 100%;
	padding: 5px;
}
.terminal__drawer__table {
	width: 100%;
	overflow-x: auto;
	margin-left: 10px;
	margin-right: 10px;
}
.terminal__drawer__tableContainer {
	min-width: 1200px;
	max-width: 100%;
}
.terminal__liftContainer {
	min-width: 250px;
	max-width: 250px;
}
.terminal__jsonContainer {
	min-width: 450px;
	max-width: 450px;
}
.terminal__table__listContainer {
	height: 430px;
	overflow-y: auto;
	border-radius: 0px 0px 5px 5px;
}
.terminal__table__listItem {
	cursor: pointer;
	padding-top: 5px;
	padding-bottom: 5px;
	display: flex;
}
.terminal__tableHeader__50 {
	width: 50px;
	min-width: 50px;
	padding-left: 10px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.terminal__tableHeader__100 {
	width: 100px;
	min-width: 100px;
	padding-left: 10px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.terminal__tableHeader__200 {
	width: 200px;
	min-width: 150px;
	padding-left: 10px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.terminal__tableTagFix {
	padding-left: 10px;
}
.terminal__tableIconFix {
	text-align: center;
}
.terminal__tableHeader__dark {
	background-color: #1d1d1d;
	padding: 5px;
}
.terminal__tableHeader__blue {
	background-color: #050c17;
	padding: 5px;
}
.terminal__tableHeader__light {
	background-color: white;
	padding: 5px;
}
.terminal__table__emptyContainer {
	width: 100%;
	padding: 10px;
	border-radius: 0px 0px 5px 5px;
	height: 430px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.terminal__typeTag {
	width: 90px;
	text-align: center;
}
.terminal__positionTag {
	width: 30px;
	text-align: center;
}
.terminal__tableRowSelected {
	background-color: $item__info;
}
.terminal__container__dark {
	background-color: #1d1d1d;
	border-radius: 5px;
}
.terminal__container__light {
	background-color: #fafafa;
	border-radius: 5px;
}
.terminal__container__blue {
	background-color: #050c17;
	border-radius: 5px;
}
.terminal__jsonViewer__copyText {
	cursor: pointer;
	padding: 5px;
	color: $item__warning;
	display: flex;
	justify-content: flex-end;
}
.terminal__jsonViewer__copyEmptyText {
	height: 30px;
}
.terminal__jsonViewer__container {
	height: 425px;
	overflow-y: auto;
	margin-top: 10px;
	padding: 5px;
}
.terminal__jsonViewer__emptyFixTop {
	margin-top: 125px;
}
.terminal__jsonViewer__emptyFixTop2 {
	margin-top: 100px;
}
.terminal__lift__heightFix {
	height: 475px;
}
.terminal__container {
	width: 100%;
	padding: 5px;
	border-radius: 8px;
	height: 100%;
}
.terminal__lift__minifiedView {
	overflow-y: auto;
	max-height: 380px;
}
