@import "~bootstrap/scss/bootstrap";
@import "./variables.scss";
@import "./buttonsStyle.scss";
@import "./layout.scss";
@import "./drawer.scss";
@import "./loginStyles.scss";
@import "./configurationStyle.scss";
@import "./edgeManagement.scss";
@import "./tools.scss";
@import "./monitoringStyles.scss";
@import "./terminal.scss";
@import "./components.scss";
@import "./mapViewStyles.scss";

html * {
	font-family: Arial, Helvetica, sans-serif;
}

.generalStyles__flex {
	display: flex;
}
.generalStyles__flexBreak {
	display: flex;
	word-break: break-all;
}
.generalStyles__flexCenter {
	display: flex;
	justify-content: center;
	align-items: center;
}

.generalStyles__flexEnd {
	display: flex;
	justify-content: flex-end;
}
.generalStyles__flexWrap {
	display: flex;
	flex-wrap: wrap;
}
.generalStyles__spaceBetween {
	display: flex;
	justify-content: space-between;
}
.generalStyles__spaceEvenly {
	display: flex;
	justify-content: space-evenly;
}
.generalStyles__decorationNone {
	text-decoration: none;
}
.generalStyles__hoverCursor {
	cursor: pointer;
}
.generalStyles__textCenter {
	text-align: center;
}

// ******************************************************************
// MIXINGS
// ******************************************************************
// GENRAL
.generalStyles__info__hover:hover {
	color: $item__info !important;
}
.generalStyles__info {
	color: $item__info !important;
}
.generalStyles__error {
	color: $item__error !important;
}
.generalStyles__warning {
	color: $item__warning !important;
}
.generalStyles__success {
	color: $item__success !important;
}
.generalStyles__icon {
	color: $item__icon__1 !important;
}
.generalStyles__line__dark {
	color: gray;
}
.generalStyles__line__light {
	color: $item__info;
}
.generalStyles__line__blue {
	color: #17314d;
}
.generalStyles__textColor__dark {
	color: white;
}
.generalStyles__textColor__blue {
	color: white;
}
.generalStyles__textColor__light {
	color: black;
}
// ******************************************************************
.swalStyle__box {
	position: relative;
	width: 100%;
}
.generalStyles__layoutHeight {
	min-height: 100vh !important;
}
.generalStyles__textEllipsis {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.generalStyles__width100 {
	width: 100%;
}
.generalStyles__width50px {
	width: 50px;
}
.generalStyles__width100px {
	width: 100px;
}
.generalStyles__width150px {
	width: 150px;
}
.generalStyles__width200px {
	width: 200px;
}
.generalStyles__width250px {
	width: 250px;
}
.generalStyles__width300px {
	width: 300px;
}
.generalStyles__width350px {
	width: 350px;
}
.generalStyles__width400px {
	width: 400px;
}
.generalStyles__fitContent {
	width: fit-content;
}
.generalStyles__overFlowXAuto {
	overflow-x: auto;
}
.generalStyles__justifySpaceBetween {
	justify-content: space-between;
}
.generalStyles__flexEnd {
	justify-content: flex-end;
}
.generalStyles__justifyCenter {
	justify-content: center;
}
.position-absolute {
	position: absolute;
}
.generalStyles__deleteItemIcon {
	font-size: 50px;
}
.generalStyles__modal1 {
	width: 50% !important;
}
.generalStyles__tableActionBox {
	width: auto;
}
.generalStyles__sunIcon {
	color: #da922e;
	font-size: 20px;
	margin-left: 10px;
}
.generalStyles__moonIcon {
	color: silver;
	font-size: 20px;
	margin-left: 10px;
}
.generalStyles__drawerDivider {
	border-color: $item__info !important;
}
.generalStyles__drawerDividerWarning {
	border-color: $item__warning !important;
}
.generalStyles__drawerDividerSuccess {
	border-color: $item__success !important;
}
.generalStyles__drawerDividerError {
	border-color: $item__error !important;
}
.generalStyles__mrFix {
	margin-right: 10px;
}
.generalStyles__mrFix25 {
	margin-right: 25px;
}
.generalStyles__mlFix {
	margin-left: 10px;
}
.generalStyles__mlFix25 {
	margin-left: 25px;
}
.generalStyles__mlFix15 {
	margin-left: 25px;
}
.generalStyles__ellipsis__100px {
	text-overflow: ellipsis;
	overflow: hidden;
	width: 100px;
	white-space: nowrap;
}
.generalStyles__ellipsis__125px {
	text-overflow: ellipsis;
	overflow: hidden;
	width: 125px;
	white-space: nowrap;
}
.generalStyles__ellipsis__150px {
	text-overflow: ellipsis;
	overflow: hidden;
	width: 150px;
	white-space: nowrap;
}
.generalStyles__ellipsis__200px {
	text-overflow: ellipsis;
	overflow: hidden;
	width: 200px;
	white-space: nowrap;
}
.generalStyles__ellipsis__250px {
	text-overflow: ellipsis;
	overflow: hidden;
	width: 250px;
	white-space: nowrap;
}
.generalStyles__staticInputLabel {
	font-size: 12px;
}
.generalStyles__tableHeaderLink {
	color: $item__info;
	font-weight: bolder;
	cursor: pointer;
}
.generalStyles__breadcrumbItem {
	color: $item__info;
	font-weight: bolder;
	cursor: pointer;
}
.generalStyles__breadcrumbItemInactive {
	font-weight: bolder;
	color: rgb(204, 204, 204);
	cursor: pointer;
}
.generalStyles__header {
	padding: 0;
	height: 72px;
}
.generalStyles__breadcrumb {
	margin: 20px 0 0 20px !important;
}
.generalStyles__userLoggedEmail {
	margin-top: -15px;
}
.generalStyles__userLoggedIcon {
	margin-top: -25px;
}
.generalStyles__inputNumber {
	width: 100px !important;
	margin-left: -12px;
	margin-right: -12px;
	border-top: none;
	border-bottom: none;
}
.generalStyles__inputNumberFull {
	width: 100% !important;
	margin-left: -12px;
	margin-right: -12px;
	border-top: none;
	border-bottom: none;
}
.generalStyles__pageHeader {
	display: flex;
	flex-wrap: wrap;
}
.generalStyles__dashboardCard {
	width: 300px;
	margin: 10px;
}
.generalStyles__dashboardCardContainer {
	display: flex;
	flex-wrap: wrap;
	margin-top: 20px;
}
.generalStyles__infoTooltipIcon {
	color: $item__info;
	width: 30px;
	font-size: 16px;
	padding-top: 5px !important;
}
.generalStyles__warningTooltipIcon {
	color: $item__warning;
	width: 30px;
	font-size: 16px;
	padding-top: 5px !important;
}
.generalStyles__infoTooltipIconBlank {
	width: 43px;
}
.generalStyles__inputFlexName {
	width: 170px;
	font-size: 12px;
}
.generalStyles__inputFlexNameLarge {
	width: 275px;
	font-size: 12px;
}
.generalStyles__inputFlexRequired {
	color: $item__error;
	margin-right: 5px;
}
.generalStyles__searchDropdown {
	padding: 10px;
}
.generalStyles__searchDropdown__lx {
	padding: 10px;
	width: 200px;
}
.generalStyles__searchDropdown__m {
	padding: 10px;
	width: 150px;
}
// ******************************************************************
// NOTIFICATIONS
.generalStyles__notificationSuccess {
	background-color: $item__success__background !important;
	color: $item__success !important;
}
.generalStyles__notificationInfo {
	background-color: $item__info__background !important;
	color: $item__info !important;
}
.generalStyles__notificationError {
	background-color: $item__error__background !important;
	color: $item__error !important;
}
.generalStyles__notificationWarning {
	background-color: $item__warning__background !important;
	color: $item__warning !important;
}
.ant-notification-notice-message {
	color: black !important;
}

// ******************************************************************
// ICONS
.generalStyles__themeIcon1__light {
	color: $item__theme__icon__light;
}
.generalStyles__themeIcon1__dark {
	color: $item__theme__icon__dark;
}
.generalStyles__themeIcon1__blue {
	color: $item__theme__icon__blue;
}
// ******************************************************************
// APPEARANCE
.settings__cardImage {
	width: 90px;
	height: 60px;
	margin-top: 15px;
	margin-bottom: 15px;
	margin-left: 15px;
	border-radius: 10px;
}
.settings__themeTitle {
	font-size: 11px !important;
}
.settings__activeBox {
	height: 20px;
	width: 20px;
	font-size: 10px;
	color: $item__warning;
	text-align: center;
	margin-top: 12px;
	margin-right: 5px;
}
.settings__activeBox__light {
	background-color: $item__background__light;
}
.settings__activeBox__dark {
	background-color: black;
}
.settings__activeBox__blue {
	background-color: $item__monitoring__floor__blue;
}
.settings__activeCard {
	width: 120px;
	border-radius: 5px;
	border: solid 1px $item__info !important;
	margin: 10px;
}
.settings__card {
	width: 120px;
	border-radius: 5px;
	margin: 10px;
}
.settings__sunIcon {
	color: rgb(255, 174, 0);
	margin-right: 5px;
}
.settings__moonIcon {
	color: rgb(149, 194, 212);
}
.settings__titleIcon {
	color: $item__global;
}
.table-row-active {
	background-color: rgb(41, 104, 192, 0.1);
}
.table-row-light {
	background-color: #fafafa;
}
.table-row-dark {
	background-color: #1d1d1d;
}
.table-row-blue {
	background-color: #050c17;
}
.generalStyles__textJustify {
	text-align: justify;
}

/* custom-light-table.css */
.custom-light-table .ant-table {
	background-color: #ffffff; /* Light background */
	color: #000000; /* Dark text */
}

.custom-light-table .ant-table-thead > tr > th {
	background-color: #f3f3f3; /* Light header background */
	color: black; /* Dark header text */
}

.custom-light-table .ant-table-tbody > tr:nth-child(odd) > td {
	background-color: #ffffff; /* White background for odd rows */
}

.custom-light-table .ant-table-tbody > tr:nth-child(even) > td {
	background-color: #fafafa; /* Slightly darker white background for even rows */
	color: #000000; /* Ensure text color is consistent */
}

// map view styles
.box {
	position: relative;
	width: 200px;
	height: 100px;
	background-color: #4caf50;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
}

.box::after {
	content: "";
	position: absolute;
	top: 50%;
	right: -20px; /* Ajusta para que no haya separación */
	transform: translateY(-50%);
	width: 0;
	height: 0;
	border-left: 20px solid #4caf50; /* Color de fondo del cuadro */
	border-top: 15px solid transparent;
	border-bottom: 15px solid transparent;
}

.box::before {
	content: "";
	position: absolute;
	top: 50%;
	right: -24px; /* Ajusta para el borde */
	transform: translateY(-50%);
	width: 0;
	height: 0;
	border-left: 24px solid red; /* Color del borde de la flecha */
	border-top: 18px solid transparent;
	border-bottom: 18px solid transparent;
}
