@import "./variables.scss";

// ******************************************************************
// MIXINGS

// ******************************************************************
// GENERAL STYLES
.component__table__noDataBox {
  height: 300px;
}

.component__table__noDataIcon {
  font-size: 25px;
}

.component__mapViewPin__tip {
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid $item__info;
  position: absolute;
}

.component__mapViewPin__tip__dark {
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid #636363;
  position: absolute;
}

.component__mapViewPin__tip__blue {
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid #11273f;
  position: absolute;
}

.component__mapViewPin__tip__light {
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid #e2e3e4;
  position: absolute;
}
// ******************************************************************
