@import "./variables.scss";
// ******************************************************************
// GENERAL STYLES
@keyframes blinkDark {
  0% {
    border-color: #5f5f5f; /* Original color */
  }
  50% {
    border-color: $item__error; /* Blinking color */
  }
  100% {
    border-color: #5f5f5f;
  }
}
@keyframes blinkLight {
  0% {
    border-color: #e2e3e4; /* Original color */
  }
  50% {
    border-color: $item__error; /* Blinking color */
  }
  100% {
    border-color: #e2e3e4;
  }
}
@keyframes blinkBlue {
  0% {
    border-color: #11273f; /* Original color */
  }
  50% {
    border-color: $item__error; /* Blinking color */
  }
  100% {
    border-color: #11273f;
  }
}

@keyframes blinkTextLight {
  0% {
    color: black; /* Original color */
  }
  50% {
    color: $item__error; /* Blinking color */
  }
  100% {
    color: black;
  }
}

@keyframes blinkTextDark {
  0% {
    color: white; /* Original color */
  }
  50% {
    color: $item__error; /* Blinking color */
  }
  100% {
    color: white;
  }
}

.mapViewStyles__box__minimized {
  width: 25px;
  height: 25px;
}
.mapViewStyles__box__dark {
  background-color: black;
  border: solid 2px #5f5f5f;
}
.mapViewStyles__box__light {
  background-color: white;
  border: solid 2px #e2e3e4;
}
.mapViewStyles__box__blue {
  background-color: #16314d;
  border: solid 2px #11273f;
}
.mapViewStyles__box {
  position: relative;
  text-align: center;
  border-radius: 8px;
  transition: width 0.5s ease, height 0.5s ease;
}
.mapViewStyles__box::before {
  content: "";
  position: absolute;
  border-style: solid;
  width: 0;
  height: 0;
}

.mapViewStyles__title__error__dark {
  animation: blinkTextDark 1s infinite;
}
.mapViewStyles__title__error__blue {
  animation: blinkTextDark 1s infinite;
}
.mapViewStyles__title__error__light {
  animation: blinkTextLight 1s infinite;
}

// ******************************************************************
// ELEVATOR
.mapViewStyles__icon__elevator__minimized {
  height: 12px;
  width: 12px;
  margin-top: -2px;
  margin-left: -1px;
  transition: width 0.5s ease, height 0.5s ease; /* Transición suave en el tamaño */
}
.mapViewStyles__box:hover .mapViewStyles__icon__elevator__minimized {
  height: 12px;
  width: 12px;
  margin-top: 4px;
  margin-bottom: 4px;
}
.mapViewStyles__icon__elevator__expanded {
  height: 12px;
  width: 12px;
  margin-top: 4px;
  margin-bottom: 4px;
}
// ******************************************************************
// ESCALATOR
.mapViewStyles__icon__escalator__minimized {
  height: 28px;
  width: 28px;
  margin-top: -4px;
  margin-left: -3px;
  transition: width 0.5s ease, height 0.5s ease; /* Transición suave en el tamaño */
}
.mapViewStyles__box:hover .mapViewStyles__icon__escalator__minimized {
  height: 28px;
  width: 28px;
  margin-top: -4px;
  margin-left: 6px;
}
.mapViewStyles__icon__escalator__expanded {
  height: 28px;
  width: 28px;
  margin-top: -4px;
  margin-left: 6px;
}
// ******************************************************************
// TOP
@keyframes blinkTopDark {
  50% {
    border-color: #5f5f5f;
  }
  50% {
    border-color: transparent transparent $item__error transparent;
  }
}
@keyframes blinkTopLight {
  50% {
    border-color: #e2e3e4;
  }
  50% {
    border-color: transparent transparent $item__error transparent;
  }
}
@keyframes blinkTopBlue {
  50% {
    border-color: #11273f;
  }
  50% {
    border-color: transparent transparent $item__error transparent;
  }
}
.mapViewStyles__box__error__top__dark {
  animation: blinkDark 1s infinite;
}
.mapViewStyles__box__error__top__light {
  animation: blinkLight 1s infinite;
}
.mapViewStyles__box__error__top__blue {
  animation: blinkBlue 1s infinite;
}
.mapViewStyles__box__error__top__dark::before {
  animation: blinkTopDark 1s infinite;
}
.mapViewStyles__box__error__top__light::before {
  animation: blinkTopLight 1s infinite;
}
.mapViewStyles__box__error__top__blue::before {
  animation: blinkTopBlue 1s infinite;
}
.mapViewStyles__arrow__top__dark::before {
  top: -20px; /* Adjust this value based on border size */
  left: 50%;
  transform: translateX(-50%);
  border-width: 10px;
  border-color: transparent transparent #5f5f5f transparent;
}
.mapViewStyles__arrow__top__light::before {
  top: -20px; /* Adjust this value based on border size */
  left: 50%;
  transform: translateX(-50%);
  border-width: 10px;
  border-color: transparent transparent #e2e3e4 transparent;
}
.mapViewStyles__arrow__top__blue::before {
  top: -20px; /* Adjust this value based on border size */
  left: 50%;
  transform: translateX(-50%);
  border-width: 10px;
  border-color: transparent transparent #11273f transparent;
}
// ******************************************************************
// BOTTOM
@keyframes blinkBottomDark {
  50% {
    border-color: #5f5f5f;
  }
  50% {
    border-color: $item__error transparent transparent transparent;
  }
}
@keyframes blinkBottomLight {
  50% {
    border-color: #e2e3e4;
  }
  50% {
    border-color: $item__error transparent transparent transparent;
  }
}
@keyframes blinkBottomBlue {
  50% {
    border-color: #11273f;
  }
  50% {
    border-color: $item__error transparent transparent transparent;
  }
}
.mapViewStyles__box__error__bottom__dark {
  animation: blinkDark 1s infinite;
}
.mapViewStyles__box__error__bottom__light {
  animation: blinkLight 1s infinite;
}
.mapViewStyles__box__error__bottom__ {
  animation: blinkBlue 1s infinite;
}
.mapViewStyles__box__error__bottom__dark::before {
  animation: blinkBottomDark 1s infinite;
}
.mapViewStyles__box__error__bottom__light::before {
  animation: blinkBottomLight 1s infinite;
}
.mapViewStyles__box__error__bottom__blue::before {
  animation: blinkBottomBlue 1s infinite;
}
.mapViewStyles__arrow__bottom__dark::before {
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 10px;
  border-color: #5f5f5f transparent transparent transparent;
}
.mapViewStyles__arrow__bottom__light::before {
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 10px;
  border-color: #e2e3e4 transparent transparent transparent;
}
.mapViewStyles__arrow__bottom__blue::before {
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 10px;
  border-color: #11273f transparent transparent transparent;
}
// ******************************************************************
// RIGHT
@keyframes blinkRightDark {
  50% {
    border-color: #5f5f5f;
  }
  50% {
    border-color: transparent transparent transparent $item__error;
  }
}
@keyframes blinkRightLight {
  50% {
    border-color: #e2e3e4;
  }
  50% {
    border-color: transparent transparent transparent $item__error;
  }
}
@keyframes blinkRightBlue {
  50% {
    border-color: #11273f;
  }
  50% {
    border-color: transparent transparent transparent $item__error;
  }
}
.mapViewStyles__box__error__right__dark {
  animation: blinkDark 1s infinite;
}
.mapViewStyles__box__error__right__light {
  animation: blinkLight 1s infinite;
}
.mapViewStyles__box__error__right__blue {
  animation: blinkBlue 1s infinite;
}
.mapViewStyles__box__error__right__dark::before {
  animation: blinkRightDark 1s infinite;
}
.mapViewStyles__box__error__right__light::before {
  animation: blinkRightLight 1s infinite;
}
.mapViewStyles__box__error__right__blue::before {
  animation: blinkRightBlue 1s infinite;
}
.mapViewStyles__arrow__right__dark::before {
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  border-width: 10px;
  border-color: transparent transparent transparent #5f5f5f;
}
.mapViewStyles__arrow__right__light::before {
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  border-width: 10px;
  border-color: transparent transparent transparent #e2e3e4;
}
.mapViewStyles__arrow__right__blue::before {
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  border-width: 10px;
  border-color: transparent transparent transparent #11273f;
}
// ******************************************************************
// LEFT
@keyframes blinkLeftDark {
  50% {
    border-color: #5f5f5f;
  }
  50% {
    border-color: transparent $item__error transparent transparent;
  }
}
@keyframes blinkLeftLight {
  50% {
    border-color: #e2e3e4;
  }
  50% {
    border-color: transparent $item__error transparent transparent;
  }
}
@keyframes blinkLeftBlue {
  50% {
    border-color: #11273f;
  }
  50% {
    border-color: transparent $item__error transparent transparent;
  }
}
.mapViewStyles__box__error__left__dark {
  animation: blinkDark 1s infinite;
}
.mapViewStyles__box__error__left__light {
  animation: blinkLight 1s infinite;
}
.mapViewStyles__box__error__left__blue {
  animation: blinkBlue 1s infinite;
}
.mapViewStyles__box__error__left__dark::before {
  animation: blinkLeftDark 1s infinite;
}
.mapViewStyles__box__error__left__light::before {
  animation: blinkLeftLight 1s infinite;
}
.mapViewStyles__box__error__left__blue::before {
  animation: blinkLeftBlue 1s infinite;
}
.mapViewStyles__arrow__left__dark::before {
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  border-width: 10px;
  border-color: transparent #5f5f5f transparent transparent;
}
.mapViewStyles__arrow__left__light::before {
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  border-width: 10px;
  border-color: transparent #e2e3e4 transparent transparent;
}
.mapViewStyles__arrow__left__blue::before {
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  border-width: 10px;
  border-color: transparent #11273f transparent transparent;
}
// ******************************************************************
// VERTICAL SM
.mapViewStyles__box__sm__vertical__expanded {
  width: 40px;
  height: 40px;
}
.mapViewStyles__box__sm__vertical:hover {
  width: 40px;
  height: 40px;
}
// ******************************************************************
// VERTICAL MD
.mapViewStyles__box__md__vertical__expanded {
  width: 40px;
  height: 56px;
}
.mapViewStyles__box__md__vertical:hover {
  width: 40px;
  height: 56px;
}
// ******************************************************************
// VERTICAL LG
.mapViewStyles__box__lg__vertical__expanded {
  width: 40px;
  height: 72px;
}
.mapViewStyles__box__lg__vertical:hover {
  width: 40px;
  height: 72px;
}
// ******************************************************************
// HORIZONTAL SM
.mapViewStyles__box__sm__horizontal__expanded {
  width: 40px;
  height: 40px;
}
.mapViewStyles__box__sm__horizontal:hover {
  width: 40px;
  height: 40px;
}
// ******************************************************************
// HORIZONTAL MD
.mapViewStyles__box__md__horizontal__expanded {
  width: 64px;
  height: 40px;
}
.mapViewStyles__box__md__horizontal:hover {
  width: 64px;
  height: 40px;
}
// ******************************************************************
// HORIZONTAL LG
.mapViewStyles__box__lg__horizontal__expanded {
  width: 88px;
  height: 40px;
}
.mapViewStyles__box__lg__horizontal:hover {
  width: 88px;
  height: 40px;
}
