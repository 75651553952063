@import "./variables";

.layout__contentPage {
  padding: 25px;
  margin: 25px;
  overflow-x: auto;
}
.layout__contentPageMobile {
  padding: 5px;
  margin: 5px;
  padding-top: 50px;
  overflow-x: auto;
}
.layout__contentPage__dark {
  background-color: $item__background__dark;
  padding: 15px;
}
.layout__contentPage__light {
  background-color: $item__background__light;
  padding: 15px;
}
.layout__contentPage__blue {
  background-color: $item__background__blue;
  padding: 15px;
}
.layout__header {
  height: 100px;
}
.layout__sideBarLogo {
  width: 180px;
  height: 38px;
  margin-top: 15px;
  margin-left: 20px;
  margin-bottom: 40px;
}
.layout__sideBarMobileLogo {
  width: 50px;
  height: 50px;
  margin-left: 15px;
}
.layout__footerText {
  color: $item__title;
  text-align: center;
}
.layout__footerTextMobile {
  font-size: 10px;
}
.layout__footerTextDesktop {
  font-size: 14px;
}
.layout__sideBar {
  width: 390px;
  background-color: aqua;
}
.layout__userAvatarMobile {
  margin-right: -35px;
}
