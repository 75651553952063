@import "./variables.scss";

// ******************************************************************
// MIXINGS

// ******************************************************************
// GENERAL STYLES

.edgeManagement__floorNameTootle {
	width: 20px;
}
.edgeManagement__floorNameName {
	width: 100px;
}
.edgeManagement__floorNamesTag {
	width: 40px;
	text-align: center;
}
.edgeManagement__bankInputTotalOption {
	width: 100px !important;
	margin-top: -10px;
	margin-bottom: 5px;
}
.edgeManagement__bankInputTotalOptionFullWith {
	width: 50px !important;
	text-align: center;
	margin-bottom: 10px !important;
}
.edgeManagement__bankInputNameOption {
	width: 150px;
}
.edgeManagement__checkOffsetsTableButton {
	width: 100%;
	margin-top: -10px;
	background-color: $item__info !important;
}
.edgeManagement__banksGenerateOffsetsButton {
	background-color: $item__warning !important;
	width: 100%;
	margin-top: 5px;
}
.edgeManagement__buttonSecondaryContainer {
	display: flex;
	justify-content: space-between;
	margin-top: 15px;
}
.edgeManagement__bankTableColumn {
	border: solid 1px #868686;
	width: 150px;
	text-align: center;
	padding: 5px;
	background-color: $item__info;
}
.edgeManagement__bankTableRow1 {
	border: solid 1px #868686;
	width: 150px;
	height: 35px;
}
.edgeManagement__bankTableRowLight {
	border: solid 1px #868686;
	width: 150px;
	height: 35px;
	background-color: rgb(236, 236, 236);
}
.edgeManagement__bankTableRow__dark {
	border: solid 1px #868686;
	width: 150px;
	height: 35px;
	background-color: #303030;
}
.edgeManagement__bankTableRow__light {
	border: solid 1px #868686;
	width: 150px;
	height: 35px;
	background-color: #303030;
}
.edgeManagement__bankTableRow__blue {
	border: solid 1px #868686;
	width: 150px;
	height: 35px;
	background-color: #303030;
}
.edgeManagement__bankTableRowPadding {
	padding: 5px 0px 0px 10px;
	font-weight: bolder;
	color: $item__info;
}
.edgeManagement__bankTableInput {
	border-style: none;
	width: 100% !important;
}
.edgeManagement__bankPositionTag {
	width: 100px;
	height: 31px !important;
	padding-top: 5px !important;
}
.edgeManagement__banksHeader {
	width: 150px;
	border: solid 1px #434343;
	padding: 7px;
}
.edgeManagement__banksHeader_ {
	width: 130px;
}
.edgeManagement__banksHeader__ {
	width: 150px;
}
.edgeManagement__buttonsContainer {
	width: 300px;
}
.edgeManagement__banksOffsetsButtonWidh {
	width: 200px;
}
