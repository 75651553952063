@import "./variables.scss";

// ******************************************************************
// MIXINGS
input[type="number"]::-webkit-inner-spin-button {
	opacity: 1;
}
// ******************************************************************
// GENERAL STYLES
.configuration__editRootPermisionsButton {
	margin-top: 20px;
}
.configuration__containerGrid {
	overflow-x: scroll;
	margin-top: 50px;
	margin-bottom: 50px;
}
.configuration__horizontalGridTitle {
	width: 200px;
	min-width: 200px;
}
.configuration__horizontalGridOffset {
	width: 100px;
	min-width: 100px;
}
.configuration__verticalGrid {
	padding: 5px;
	border: solid 1px rgb(107, 107, 107);
}
.configuration__modalTableOffsets {
	height: 60vh;
	overflow-y: scroll;
	overflow-x: hidden;
	justify-content: center;
	padding-top: 50px;
	margin-bottom: 50px;
}
.configuration__offsetNameBox {
	width: 200px;
	padding-left: 10px;
	margin-bottom: 5px;
	color: $item__info;
	font-weight: bolder;
	font-size: 15px;
	margin-right: 5px;
}
.configuration__offsetOffsetBox {
	width: 100%;
}
.configuration__errorInputOffset {
	border: solid 1px $item__error !important;
	background-color: #ffdfe4 !important;
	color: black !important;
}
.configuration__listOver:hover {
	background-color: #c5e1e8;
	cursor: pointer;
	color: black;
}
.configuration__statusBitCardFix {
	margin-left: -10px;
}
.configuration__statusBitCardFix2 {
	margin-left: -20px;
}
.configuration__inputSizeXXS {
	width: 55px !important;
}
.configuration__inputSizeXS {
	width: 90px !important;
}
.configuration__inputSizeS {
	width: 120px !important;
}
.configuration__inputSizeM {
	width: 125px !important;
}
.configuration__inputSizeL {
	width: 150px !important;
}
.configuration__inputSizeXL {
	width: 200px !important;
}
.configuration__divCheckbox {
	display: flex;
	align-items: center;
	justify-content: center;
	border: solid 1px #434343;
	height: 33px;
}
.configuration__inputHeader {
	background-color: $item__info !important;
	color: white !important;
	height: 28px;
	border: solid 1px gray;
}
.configuration__bitRangeTag {
	font-size: 12px;
	font-weight: bolder;
	color: #2968c0;
	margin-left: 5px;
	margin-right: 5px;
}
.configuration__bitRangeTag2 {
	font-size: 11px;
	font-weight: bolder;
	color: #2968c0;
	margin-left: 10px;
	margin-right: 10px;
}
.configuration__bitRangeInput {
	width: 60px;
	margin-right: 10px;
	margin-left: 10px;
}
.configuration__carBlockGrid {
	background-color: "#2968C0";
	color: "white";
	border: "solid 1px #e6f7ff";
	margin-bottom: "-5px";
	padding: "10px";
	font-size: "15px";
	font-weight: "bolder";
}
.configuration__offsetTableName {
	font-size: "13px";
	font-weight: "bolder";
	color: "#2968C0";
}
.configuration__offsetTableDescription {
	background-color: "#2968C0";
	color: "white";
	border: "solid 1px #e6f7ff";
	margin-bottom: "-5px";
	padding: "10px";
	font-size: "15px";
	font-weight: "bolder";
}
.configuration__itemFixWidth {
	width: 125px;
}
.configuration__carServiceTable {
	height: 300px;
	overflow-y: scroll;
	overflow: scroll;
}
.configuration__inputHeaderNo {
	width: 90px;
}
.configuration__maxModalOffsetsTable {
	min-height: 500px;
	max-height: 500px;
	display: flex;
	justify-content: center;
}
.configuration__textInput {
	border: solid 1px #434343;
	height: 32px;
	padding: 5px 0px 0px 15px;
}
.configuration__textIcon {
	border: solid 1px #434343;
	height: 32px;
	text-align: center;
	padding-top: 4px;
}
.configuration__highlightRow {
	border: solid 2px $item__info;
	transition: 0.3s;
	color: $item__info;
}
.configuration__onOverRow {
	border: solid 2px transparent;
	transition: 0.3s;
}
.configuration__columnEllipsis {
	width: 145px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.configuration__configurationNameTitle {
	font-size: 18px;
	font-weight: bolder;
	color: $item__info;
}
