.map {
  width: 100%;
  height: 100vh;
}

.MapViewSlide {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr !important;
  grid-gap: 8px;
  width: 100%;
}

.carouselButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #3c3c3c;
  border: none;
  outline: none;
  cursor: pointer;
}

.carouselButton:hover {
  background-color: #2c2c2c;
}
