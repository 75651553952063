@import "./variables.scss";

// ******************************************************************
// MIXINGS
// ******************************************************************
// GENERAL STYLES
.buttonStyle__1 {
	background-color: $item__info !important;
	border-style: none;
	color: white !important;
}
.buttonStyle__2 {
	background-color: $item__warning !important;
	color: white !important;
	border: solid 1px $item__warning !important;
}
.buttonStyle__4 {
	border: solid 1px $item__warning !important;
	color: $item__warning !important;
	border-style: none;
}
.buttonStyle__3 {
	width: 46%;
	margin-left: 2%;
	margin-right: 2%;
	border-style: none;
}
.buttonStyle__5 {
	width: 96%;
	margin-left: 2%;
	margin-right: 2%;
	border-style: none;
}
.buttonStyle__6 {
	width: 96%;
	color: white !important;
	background-color: $item__info !important;
	margin-left: 2%;
	margin-right: 2%;
	border-style: none;
}
.buttonStyle__7 {
	color: $item__error !important;
	border: solid 1px $item__error !important;
}
.buttonStyle__8 {
	color: $item__info !important;
	border: solid 1px $item__info !important;
}
.buttonStyle__9 {
	border: solid 1px $item__warning !important;
	color: $item__warning !important;
	border-style: none;
	&:hover {
		background-color: $item__warning !important;
		color: white !important;
		border: solid 1px $item__warning !important;
	}
}
.buttonStyle__10 {
	border: solid 1px $item__info !important;
	color: $item__info !important;
	border-style: none;
	&:hover {
		background-color: $item__info !important;
		color: white !important;
		border: solid 1px $item__info !important;
	}
}
.buttonStyle__11 {
	border: solid 1px gray !important;
	color: gray !important;
	border-style: none;
	&:hover {
		background-color: $item__info !important;
		color: white !important;
		border: solid 1px $item__info !important;
	}
}
.buttonStyle__12 {
	border: solid 1px $item__success !important;
	color: $item__success !important;
	border-style: none;
	&:hover {
		background-color: $item__success !important;
		color: white !important;
		border: solid 1px $item__success !important;
	}
}
.buttonStyle__13 {
	background-color: $item__success !important;
	color: white !important;
	border: solid 1px $item__success !important;
}
.buttonStyle__14 {
	border: solid 1px $item__error !important;
	color: $item__error !important;
	border-style: none;
	&:hover {
		background-color: $item__error !important;
		color: white !important;
		border: solid 1px $item__error !important;
	}
}
.buttonStyle__15 {
	width: 96%;
	background-color: $item__error !important;
	margin-left: 2%;
	margin-right: 2%;
	border-style: none;
}
.buttonStyle__16 {
	width: 96%;
	margin-left: 2%;
	margin-right: 2%;
	border-style: none;
}
.buttonStyle__17 {
	border: solid 1px $item__info !important;
	background-color: $item__info !important;
	color: white !important;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	&:hover {
		background-color: $item__info !important;
		color: white !important;
		border: solid 1px $item__info !important;
	}
}
.buttonStyle__18 {
	border: solid 1px #284266 !important;
	background-color: #284266 !important;
	color: white !important;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	&:hover {
		background-color: #284266 !important;
		color: white !important;
		border: solid 1px #284266 !important;
	}
}
.buttonStyle__19 {
	border: solid 1px $item__warning !important;
	background-color: $item__warning !important;
	color: white !important;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	&:hover {
		background-color: $item__warning !important;
		color: white !important;
		border: solid 1px $item__warning !important;
	}
}
.buttonStyle__20 {
	border: solid 1px #816f34 !important;
	background-color: #816f34 !important;
	color: white !important;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	&:hover {
		background-color: #816f34 !important;
		color: white !important;
		border: solid 1px #816f34 !important;
	}
}
.buttonStyle__21 {
	border: solid 1px $item__success !important;
	background-color: $item__success !important;
	color: white !important;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	&:hover {
		background-color: $item__success !important;
		color: white !important;
		border: solid 1px $item__success !important;
	}
}
.buttonStyle__22 {
	border: solid 1px #24633e !important;
	background-color: #24633e !important;
	color: white !important;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	&:hover {
		background-color: #24633e !important;
		color: white !important;
		border: solid 1px #24633e !important;
	}
}
.buttonStyle__23 {
	border: solid 1px $item__error !important;
	background-color: $item__error !important;
	color: white !important;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	&:hover {
		background-color: $item__error !important;
		color: white !important;
		border: solid 1px $item__error !important;
	}
}
.buttonStyle__24 {
	border: solid 1px #973335 !important;
	background-color: #973335 !important;
	color: white !important;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	&:hover {
		background-color: #973335 !important;
		color: white !important;
		border: solid 1px #973335 !important;
	}
}
.buttonStyle__25 {
	border: solid 1px $item__theme__icon__light !important;
	color: $item__theme__icon__light !important;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	&:hover {
		background-color: $item__error !important;
		color: white !important;
		border: solid 1px $item__error !important;
	}
}
.buttonStyle__26 {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	cursor: pointer;
	&:hover {
		color: $item__warning !important;
		border: solid 1px $item__warning !important;
	}
}
.buttonStyle__basicButtonAction {
	color: white !important;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	width: 210px;
}
.buttonStyle__inactive__1__dark {
	background-color: $item__inactive__dark !important;
	color: white !important;
	border: solid 0px $item__inactive__dark !important;
}
.buttonStyle__inactive__1__light {
	background-color: $item__inactive__light !important;
	border-style: none;
}
.buttonStyle__inactive__1__blue {
	background-color: $item__inactive__blue !important;
	border-style: none;
}
.buttonStyle__small {
	width: 150px;
}
.buttonStyle__normal {
	width: 200px;
}
.buttonStyle__large {
	width: 250px;
}
